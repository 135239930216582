<template>
  <div class="hello">
    <div class="back-image">
      <img src="../assets/img/home/background-img.svg" alt="">
    </div>
    <div>
      <div class="hello-head">
        <div class="hello-head_title">
          <div class="title">
            <div style="display: flex; flex-direction: column;">
              <h1 v-if="!isPlan">ОЦЕНИТЕ СВОИ ШАНСЫ НА ВОЗВРАТ ПРАВ</h1>
              <p>за 4 минуты</p>
              <h1 v-if="isPlan" style="font-size: 42px;line-height: 54px">ПОЛУЧИТЕ ПОШАГОВЫЙ ПЛАН ВОЗВРАТА ПРАВ ОТ ВЕДУЩЕГО АВТОЮРИСТА</h1>
            </div>
            <div class="hello-head_img">
              <img v-if="!isPlan" src="../assets/img/home/image.jpg" alt="это бесплатно">
              <img v-if="isPlan" src="../assets/img/home/foto_1.jpg" alt="это бесплатно">
              <span>ЭТО БЕСПЛАТНО</span>
            </div>
          </div>
          <div class="subtitle">
            <p>Ответьте на несколько вопросов</p>
            <p>Искусственный интеллект сопоставит результаты
              с 6844 постановлениями и решениями в базе</p>
            <p>Вы получите результат, основанный на судебной практике</p>
            <p v-if="isPlan">Вы получите четкий план действий по возврату прав</p>
            <svg width="30" height="240" viewBox="0 0 30 240" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5858 239.414C14.3668 240.195 15.6332 240.195 16.4142 239.414L29.1421 226.686C29.9232 225.905 29.9232 224.639 29.1421 223.858C28.3611 223.077 27.0948 223.077 26.3137 223.858L15 235.172L3.6863 223.858C2.90525 223.077 1.63892 223.077 0.857874 223.858C0.0768254 224.639 0.0768255 225.905 0.857874 226.686L13.5858 239.414ZM13 8.74228e-08L13 238L17 238L17 -8.74228e-08L13 8.74228e-08Z" fill="#20D152"/>
            </svg>
            <a href="#ya1">Узнать шансы
              {{!isPlan ? 'на возврат прав' : 'и получить план'}}</a>
          </div>
        </div>
      </div>
      <div class="hello-check">
        <div class="hello-check_what">
          <div id="ya1" class="hello-check_what-title">
            ЧТО ДАЕТ ПРОВЕРКА?
            <div class="hello-check_what-subtitle">
              <p><span><img src="../assets/img/home/clock.svg" alt=""></span>Узнаете есть ли смысл тратить время и силы чтобы вернуть права</p>
              <p><span><img src="../assets/img/home/sud.svg" alt=""></span>Получите конкретные выдержки из судебных решений по возврату прав
                и по лишению прав!</p>
            </div>
          </div>
          <div class="hello-check_what-articles">
            <h2 v-if="!scritie">КТО МОЖЕТ УЗНАТЬ СВОИ ШАНСЫ</h2>
            <router-link v-if="!scritie" :to="{name: 'services', params: {id: numArticle[0]}}" class="articles-container">
              <div class="title">{{numArticle[0]}}</div>
              <div class="subtitle">Отказ от медицинского освидетельствования</div>
            </router-link>
            <router-link v-if="!scritie" :to="{name: 'services', params: {id: numArticle[1]}}" class="articles-container">
              <div class="title">{{numArticle[1]}}</div>
              <div class="subtitle">Управление в состоянии опьянения</div>
            </router-link>
            <router-link v-if="!scritie" :to="{name: 'services', params: {id: numArticle[2]}}" class="articles-container">
              <div class="title">{{numArticle[2]}}</div>
              <div class="subtitle">Выезд на встречную полосу</div>
            </router-link>
            <router-link :to="{name: 'services', params: {id: numArticle[3]}}" :class="{'scritie':scritie}" class="articles-container">
              <div class="title">{{numArticle[3]}}</div>
              <div class="subtitle">Скрытие с места ДТП</div>
              <div v-if="scritie" class="subtitle">Узнать свои шансы бесплатно прямо сейчас</div>
            </router-link>
            <router-link v-if="!scritie" :to="{name: 'services', params: {id: numArticle[4]}}" class="articles-container">
              <div class="title">{{numArticle[4]}}</div>
              <div class="subtitle">Управление без номеров и скрытие номеров</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      numArticle: [
        '12.26', '12.8', '12.15.4(5)', '12.27', '12.2'
      ]
    }
  },
  computed: {
    scritie () {
      return this.$route.path === '/skritie'
    },
    isPlan () {
      return this.$route.path === '/plan'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .hello {
    .back-image {
      position: absolute;
      right: 0;
      top: 40%;
      z-index: 0;
    }
    .hello-head {
      display: flex;
      flex-wrap: wrap;
      .hello-head_title {
        .title {
          display: flex;
          h1 {
            flex-basis: 50%;
            margin: 0 40px 20px 0;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 800;
            font-size: 54px;
            line-height: 68px;
            color: rgba(0, 0, 0, 0.72);
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 34px;
            color: rgba(0, 0, 0, 0.4);
          }
          .hello-head_img {
            position: relative;
            flex-basis: 40%;
            img {
              max-width: 100%;
            }
            span {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              bottom: calc(10% + 50px);
              left: -30px;
              width: 340px;
              height: 70px;
              background: #FFFFFF;
              box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
              font-style: normal;
              font-weight: bold;
              font-size: 28px;
              line-height: 34px;
              text-align: center;
              color: #0F8831;
              z-index: 1;
            }
          }
        }
        .subtitle {
          display: flex;
          flex-direction: column;
          position: relative;
          padding-left: 55px;
          font-size: 16px;
          line-height: 20px;
          svg {
            bottom: 0;
            height: calc(240px - 10px);
            width: 30px;
            position: absolute;
            left: -5px;
          }
          &::before {
            content: '';
            top: 20px;
            left: 8px;
            background: #20D152;
            background-size: cover;
            position: absolute;
            width: 4px;
            height: calc(100% - 50px);
          }
          p::before {
            content: '';
            left: 5px;
            background-color: #FFFFFF;
            position: absolute;
            border-radius: 10px;
            width: 8px;
            height: 8px;
            border: 1px solid #20D152;
            z-index: 5;
          }
          p {
            margin-bottom: 18px;
          }
          a {
            display: flex;
            text-align: center;
            align-items: center;
            margin-top: 30px;
            color: #FFFFFF;
            font-size: 16px;
            width: 180px;
            max-width: 180px;
            min-width: 140px;
            height: 60px;
            background: #20D152;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
          }
        }
      }
    }
    .hello-check {
      padding: 45px 0 45px 45px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
      margin-top: 60px;
      .hello-check_what {
        .hello-check_what-title {
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
        }
        .hello-check_what-subtitle {
          display: flex;
          flex-wrap: wrap;
          justify-content: initial;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          & p:first-child {
            margin-right: 30px;
          }
          p {
            max-width: 460px;
            span {
              float: left;
              height: 100%;
            }
          }
        }
        .hello-check_what-articles {
          display: flex;
          flex-wrap: wrap;
          h2 {
            flex-basis: 100%;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
          }
          .articles-container {
            cursor: pointer;
            width: 198px;
            height: 140px;
            margin: 10px;
            padding: 10px 0 0 10px;
            background: rgba(32, 209, 82, 0.08);
            border: 1px solid rgba(32, 209, 82, 0.16);
            box-sizing: border-box;
            border-radius: 10px;
            position: relative;
            &.scritie {
              width: 100%;
              display: flex;
              flex-direction: column;
              div {
                margin: 10px auto;
                //&:first-child {
                //  margin: 10px auto;
                //}
              }
            }
            &::before {
              content: '';
              width: 25px;
              height: 15px;
              bottom: 10px;
              right: 10px;
              position: absolute;
              background: url("../assets/img/icons/arrow.svg") center no-repeat;
            }
            .title {
              margin-bottom: 15px;
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              color: #20D152;
            }
            &:hover .title {
              color: #FFFFFF;
            }
            &:hover {
              background: #20D152;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1080px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 40px;
              line-height: 42px;
            }
          }

          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          min-width: 300px;
          img {
            max-width: 90vw;
          }
          span {
            bottom: initial;
            top: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 765px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            width: 100%;
            h1 {
              margin: 0 10px 10px 0;
              font-size: 30px;
              line-height: 32px;
            }
            .hello-head_img {
              margin-bottom: 50px;
              width: 100%;
              min-width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 550px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 24px;
              line-height: 28px;
            }
            p {
              font-size: 18px;
              line-height: 14px;
              margin-bottom: 20px;
            }
          }

          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          min-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            bottom: initial;
            top: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 24px;
              line-height: 28px;
            }
            p {
              font-size: 18px;
              line-height: 14px;
            }
          }
          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          max-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            width: 200px;
            height: 40px;
            font-size: 18px;
            top: 70%;
            left: -5px;
          }
        }
      }
      .hello-check {
        padding: 20px 0 45px 10px;
        .hello-check_what {
          display: flex;
          flex-direction: column;
          align-items: center;
          .hello-check_what-title {
          }
          .hello-check_what-subtitle {
            & p:first-child {
            }
            p {
              span {
              }
            }
          }
          .hello-check_what-articles {
            h2 {
            }
            .articles-container {
              width: calc(100% - 30px);
              height: 110px;
              .title {
              }
              &:hover .title {
              }
              &:hover {
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 330px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            p {
              top: 230px;
            }
          }
          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          max-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            width: 200px;
            height: 40px;
            font-size: 18px;
            top: 70%;
            left: -5px;
          }
        }
      }
      .hello-check {
        padding: 20px 0 45px 10px;
        .hello-check_what {
          display: flex;
          flex-direction: column;
          align-items: center;
          .hello-check_what-title {
          }
          .hello-check_what-subtitle {
            & p:first-child {
            }
            p {
              span {
              }
            }
          }
          .hello-check_what-articles {
            h2 {
            }
            .articles-container {
              width: calc(100% - 30px);
              height: 110px;
              .title {
              }
              &:hover .title {
              }
              &:hover {
              }
            }
          }
        }
      }
    }
  }
</style>
