<template>
  <div class="header">
    <div class="header-button-wrap">
      <router-link to="/">
        <img src="../../assets/img/header/logo-header.svg" alt="">
      </router-link>
      <div v-if="innerWidth > 1000">
        <div style="display: flex;">
          <router-link :to="btn.path" v-for="btn in buttons" :key="btn.path">
            <div class="header-button" :class="{'_active': $route.path === btn.path}">{{btn.title}}</div>
          </router-link>
        </div>
      </div>
      <div v-else @click="openMenu" style="margin-left: auto; cursor: pointer;">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
        </svg>
      </div>
    </div>
    <div id="modal" style="display: none;max-height: initial" class="modal">
      <div class="modal-wrapper">
        <div class="modal-title">
          Получите консультацию и персональные условия
        </div>
        <div class="modal-subtitle">
          Наш юрист поможет с выбором варианта решения и ответит на все вопросы
        </div>
        <form>
          <div class="form-wrapper">
            <label>
              <span>Ваше имя</span>
              <input type="text" v-model="name" placeholder="Введите имя">
            </label>
            <label>
              <span>E-mail</span>
              <input type="email" v-model="email" placeholder="E-mail">
            </label>
            <label>
              <span>Телефон</span>
              <input type="text" class="new-input" required placeholder="Ваш телефон" data-inputmask="'mask': '+7(999)999-99-99'"  v-model="phone">
            </label>
            <img src="../../assets/img/header/close_tabs.svg" alt="">
          </div>
        </form>
        <button type="submit" @click="closeTabsOutMessUrist">Получить бесплатную консультацию</button>
        <img style="display: none" id="closeTabsWait" src="../../assets/img/header/wait.gif" alt="">
        <span class="modal-btn-span">Все данные защищены</span>
      </div>
    </div>
    <div id="modalSubscribe" style="display: none;max-height: initial" class="modal">
      <div class="modal-wrapper">
        <a id="closeSocPopup" @click="removePopupSoc"><img src="../../assets/img/header/closePopupSoc.svg" alt=""></a>
        <div class="title">
          Спасибо!
          <p style="margin-top: 14px">
            Мы свяжемся с Вами и ответим на все Ваши вопросы. <br><br>
            Выберите мессенджер, в котором вам удобно будет получать от нас информацию по вашей заявке
          </p>
        </div>
        <div class="image-social_wrapper">
          <a onclick="window.open('https://wa.me/79250194968?text=Начать', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/wa.jpg" alt=""></a>
          <a onclick="window.open('tg://resolve?domain=Avtourist_PAU_bot', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/tg.jpg" alt=""></a>
          <a onclick="window.open('viber://pa?chatURI=avtourist-bot', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/vb.jpg" alt=""></a>
          <a onclick="window.open('https://vk.com/write-7375007', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/vk.jpg" alt=""></a>
        </div>
        <div style="text-align: center; margin-top: 15px; font-size: 12px; opacity: .3; font-style: italic">На основе ваших ответов мы прислали вам результаты проверки информации по базе судебных актов. Смотрите ниже ↓</div>
      </div>
    </div>
    <div v-if="menuModal" style="backdrop-filter: blur(8px);position: fixed;background-color: white;width: 100vw; height: 100vh;" class="modal">
      <div @click="hideMenu" style="position: absolute;top: 20px;right: 20px;">
        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </div>
      <div @click="hideMenu" style="width: 100%; display: flex; justify-content: center; flex-direction: column;">
        <router-link :to="btn.path" v-for="btn in buttons" :key="btn.path" style="text-align: center; margin: 2px 0; cursor: pointer; padding: 10px;background-color: #dcdcdc">
          <div class="header-button" :class="{'_active': $route.path === btn.path}">{{btn.title}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Inputmask from 'inputmask'
import axios from 'axios'
export default {
  name: 'Header',
  data () {
    return {
      name: null,
      email: null,
      phone: null,
      menuModal: null,
      buttons: [
        { title: 'ПРИМЕРЫ РЕШЕНИЯ СУДОВ', path: '/resheniya' },
        { title: 'ВЫИГРАННЫЕ ДЕЛА', path: '/wins' },
        { title: 'БЕСПЛАТНЫЕ КОНСУЛЬТАЦИИ', path: '/free' },
        { title: 'УЗНАТЬ ШАНСЫ И ВЫИГРАТЬ ДЕЛО', path: '/chance' }
      ],
      innerWidth: window.innerWidth
    }
  },
  created () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  methods: {
    closeTabsOutMessUrist (e) {
      if (!this.name) alert('Вам необходимо указать как вас зовут')
      else if (!this.phone && !this.email) alert('Вам необходимо указать email или телефон')
      else {
        e.target.innerText = 'Ждите..'
        document.getElementById('closeTabsWait').style.display = 'block'
        const formData = new FormData()
        formData.append('crm', '11')
        formData.append('pipe', '22')
        formData.append('contact[name]', this.name)
        formData.append('contact[199]', this.phone)
        formData.append('contact[200]', this.email)
        formData.append('lead[214]', 'magistral-avtourist.ru')
        formData.append('note', 'Окно выхода на странице ' + this.numArticle)

        axios.post('https://urist.v-avtoservice.com/api/import-lead',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => {
          e.target.innerText = 'Заявка отправлена!'
          document.getElementById('modal').remove()
          document.getElementById('modalSubscribe').style.display = 'block'

          this.$router.push({ name: 'Thanks' })
        })
      }
    },
    removePopupSoc (e) {
      document.getElementById('modalSubscribe').remove()
    },
    openMenu (e) {
      this.menuModal = true
    },
    hideMenu (e) {
      this.menuModal = false
    }
  },
  mounted () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  }
}
</script>

<style scoped lang="scss">
  .header {
    .header-button-wrap {
      display: flex;
      align-items: center;
      img {
        margin-right: 40px;
      }
      .header-button {
        padding: 10px 15px;
        border: 1px solid rgba(0,0,0,.1);
        border-radius: 3px;
        margin-right: 10px;
        transition: all .3s;
        &:hover {
          border: 1px solid rgba(0,0,0,.0);
          background: #20D152;
          color: #ffffff;
        }
        &._active {
          border: 1px solid rgba(0,0,0,.0);
          background: #20D152;
          color: #ffffff;
        }
      }
    }
    padding: 20px 50px;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 150px);
      .form-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        label {
          margin: 8px 5px;
          position: relative;
          max-width: 100%;
          min-width: 300px;
          width: 100%;
          &:focus {
            color: #0F8831;
          }
          input {
            width: 100%;
            border: 1px solid #20D152;
            box-sizing: border-box;
            border-radius: 6px;
            height: 30px;
            padding: 10px 14px;
            &:focus {
              color: #0F8831;
            }
          }
          span {
            position: absolute;
            top: -15px;
            left: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
          }
        }
        img {
          position: absolute;
          top: 170px;
        }
      }
    }
    button {
      display: block;
      width: 220px;
      height: 48px;
      background: #20D152;
      margin: 0 auto;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.36);
    }
    .modal-wrapper {
      #closeSocPopup {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .title {
        text-align: center;
        color: #595959;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 10px;
      }
      .image-social_wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a {
          margin: 3px;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .header {
      form {
        width: 100%;
        .form-wrapper {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .header {
      padding: 10px 20px;
    }
  }
</style>
